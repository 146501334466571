
import  React from 'react';
import MaterialTable, {MTableGroupbar} from 'material-table';
import EditarConceptos from './PopUpEditarConceptos';
import { render } from '@testing-library/react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import { TextareaAutosize } from '@material-ui/core';
import Cookies from 'universal-cookie';
import Avatar from 'react-avatar';
import 'src/components/stilos.css';
import AgregarConceptos from './PopUpAgregarConceptos';
import BorrarConceptos from './PopUpBorrarConceptos';

const cookies = new Cookies();


const columns=[
  {title:'TC', field:"TipoDeComprobante", filtering:false, align:'center',headerStyle:{padding:'1px'} },
  {title:'RFC Emisor', field: 'RFCEmisor', headerStyle:{padding:'1px'},defaultGroupOrder:3  },
  {title:'', field: 'RFCEmisor', headerStyle:{padding:'1px'}, render:rowData =><Avatar name={rowData.RFCEmisor} size={25} round="20px"/> },
  {title:'RFC Receptor', field: 'RFCReceptor',headerStyle:{padding:'1px'},defaultGroupOrder:4 },
  {title:'Serie', field: 'Serie', type:'numeric',align:'center',headerStyle:{padding:'1px'}  },
 
  {title: 'Año', field: 'año', type:'numeric', defaultGroupOrder:1 },
  {title:'Mes', field: 'mes', type:'numeric', defaultGroupOrder:2   },
  {title:'Descripción', field: 'Descripcion', filtering:false, align:'center', headerStyle:{padding:'1px'} },
  {title:'Carga Manual', field:'CargaManual', headerStyle:{padding:'1px'} },
  {title:'Metodo Pago', field:'MetodoPago', headerStyle:{padding:'1px'}},
  {title:'Fecha Pago', field:'FechaPago', type:'date', format:'MM/DD/YYYY', headerStyle:{padding:'1px'}},
  {title:'Folio', field: 'Folio', type:'numeric',align:'center',headerStyle:{padding:'1px'}  },
  
  {title:'Proyecto', field:'Proyecto',headerStyle:{padding:'1px'} },
  {title:'Subproyecto', field:'Subproyecto',headerStyle:{padding:'1px'} },
  
 // {title:'Concepto1',field:'Concepto1',headerStyle:{padding:'1px'} },
 // {title:'Concepto2',field:'Concepto2',headerStyle:{padding:'1px'} },
  //{title:'Concepto3',field:'Concepto3',headerStyle:{padding:'1px'} },
  {title:'Importe', field: 'importe', type:'currency', align:'center',headerStyle:{padding:'1px'}  },
  {title:'UUID', field: 'UUID', filtering:false, align:'center',headerStyle:{padding:'1px'}  }, 
  {title:'Concepto1', field: 'ConceptoAdicional1', filtering:false, align:'center',headerStyle:{padding:'1px'}  },
  {title:'Concepto2', field: 'ConceptoAdicional2', filtering:false, align:'center',headerStyle:{padding:'1px'}  },
  {title:'Concepto3', field: 'ConceptoAdicional3', filtering:false, align:'center',headerStyle:{padding:'1px'}  },
  {title:'Concepto4', field: 'ConceptoAdicional4', filtering:false, align:'center',headerStyle:{padding:'1px'}  },
  {title:'Concepto5', field: 'ConceptoAdicional5', filtering:false, align:'center',headerStyle:{padding:'1px'}  },
 

];


export default class MaterialTableFFR  extends React.Component{

  
constructor(props) {
  super(props);
  this.state = {
    data:[],
    isFetch: true,
    form: {     
      Año: '',
      Mes: '',
     
    }
  };
}

componentDidMount(){
 

  fetch(`${process.env.REACT_APP_TABLA}&annio=${this.props.año}`)
  .then(response => response.json())
 // .then(dataJson => console.log('data',dataJson))
  .then( dataJson => this.setState({data:dataJson}) )
 // .then(BarChartJson => this.setState({BarChart: BarChartJson, isFetch: false }))
 // console.log (this.state.data)

 store.addNotification({
  title: "Cargando Datos !",
  message: " Exitosamente!!",
  type: "info",  
  insert: "top",
  container: "top-center",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],

  dismiss: {
      duration: 3000,
      onScreen: true
  }

});
 
}


  render() {  

    if (cookies.get('Comp') == 2001) {
      window.location.href = "/";
     }
    
    return (
      <div>    
 <ReactNotification/>

        <MaterialTable
           
         columns={columns}
         data={this.state.data}
         title = 'Tabla de Facturas'  
         localization={{
           header:{
             actions:'Editar'
           }
         }}
         components={{
          Groupbar: props => (
            <div>
              <MTableGroupbar  {...props}  />
             {console.log(<MTableGroupbar  {...props}  />)}
            </div>
          ),
        }}
         options={{
           grouping:true,
           maxBodyHeight:"65vh",
           actionsColumnIndex:-1,
           columnsButton:true,
           
           addRowPosition:"first",
          fixedColumns: {
            left: 0, 
            right: 0
          },
         // filtering: true,
          exportButton: true,
          headerStyle: {
            backgroundColor: '#01579b',
            color: '#FFF',
            fontSize:'x-small'
          
          },
         
          rowStyle:             
          {        
           
            
            fontSize:'x-small'

          },
         
          pageSize:10,
          pageSizeOptions:[10,20,50,100],
          addRowPosition: "first",
          doubleHorizontalScroll:true,
          

        }}
        actions={[
            
          {

            icon:()=><img src='lapiz.png' width='30'/>,
            tooltip: 'Editar Conceptos',
            onClick: (event, rowData) => render(<EditarConceptos abrir={true}
               UUID={rowData.UUID}
               concepto1={rowData.ConceptoAdicional1} 
               concepto2={rowData.ConceptoAdicional2}
               concepto3={rowData.ConceptoAdicional3} 
               concepto4={rowData.ConceptoAdicional4}
               concepto5={rowData.ConceptoAdicional5} 
               proyecto={rowData.Proyecto}
               subproyecto={rowData.Subproyecto} 
               fechapago={rowData.FechaPago}
               metodopago={rowData.MetodoPago}
               mes={rowData.mes} 
               año={rowData.año}
               serie={rowData.Serie} 
               folio={rowData.Folio} 
               descripcion={rowData.Descripcion}
               importe={rowData.importe}/>)
          },  
          {

            icon: () => <img src='borrar.png' width='30'/>,
            tooltip: 'Borrar',
           // isFreeAction: true,
           onClick: (event, rowData) => render(<BorrarConceptos abrir={true}
              serie={rowData.Serie}
              folio={rowData.Folio}  
              concepto1={rowData.ConceptoAdicional1} 
              concepto2={rowData.ConceptoAdicional2} 
              concepto4={rowData.ConceptoAdicional4} 
              proyecto={rowData.Proyecto} 
              subproyecto={rowData.Subproyecto}
              UUID={rowData.UUID}
              año={rowData.año}
              mes={rowData.mes}  />)
          },
          {

            icon: ()=><img src='agregar.png' width='30'/>,
            tooltip: 'Agregar',
            isFreeAction: true,
           onClick: (event, rowData) => render(<AgregarConceptos abrir={true} />)
          },  
              

        ]}
         
    
             
         /> 

              
      </div>
    );
  };
};