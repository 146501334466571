import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import MiniDrawer from '../Menu';
import LoginPrincipal from 'src/LoginPrincipal';



function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={LoginPrincipal}/>                
                <Route exact path="/Inicio" component={MiniDrawer}/>
                <Route exact path="/Tabla" component={MiniDrawer}/>      
            </Switch>
        </BrowserRouter>
    );
}
export default Routes;