import React from 'react';
import 'fontsource-roboto';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Logout from './Logout';
import Inicio from './Inicio';
import Cookies from 'universal-cookie';
import PopUpDescargar from './components/PopUpDescargar';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './Login.css';
import MaterialTableFFR from './components/dashboardsEfect';
import TablaFFR from './components/PopUpTablaFacturas';
import SeleccionAño from './components/PopUpSeleccionAño';







const cookies = new Cookies();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
     menuButton: {
        marginRight: 36,
         },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },

    title: {
        marginRight:80,
    },
    titleImg: {
        marginRight:20,
    },

    image:{
        width:12,
        height:12,
    },


   

}));

export default function MiniDrawer(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen,] = React.useState(false);
    const R = "R";
    var operacion;
      if (cookies.get('Operacion')== R) {
          operacion=true
      } else{
          operacion=false
      }



    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

     return (
        <Router>
            <div className={classes.root}>
                <CssBaseline />
                <ReactNotification /> 
                <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open, })}>
                    
                    <Toolbar >
                        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, { [classes.hide]: open, })}>
                            <Tooltip title="Menu">
                                <MenuIcon/>
                            </Tooltip>
                        </IconButton>
                        <img  className="text-center img-fluid z-depth-0 App-logo" src={cookies.get('LComp')} alt="" width="48" height="48" />
                        <Typography className={classes.title} variant="h6" noWrap>&nbsp;&nbsp;{cookies.get('NComp')}</Typography>
                        <Typography variant="subtitle2" noWrap>Bienvenido: {cookies.get('Usu')}</Typography>
                    </Toolbar>    
                    </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> :
                                <Tooltip title="close">
                                    <ChevronLeftIcon />
                                </Tooltip>
                            }
                        </IconButton>
                    </div>
                    <Divider />
                    <Divider />

                    <List>

                     
                         {/** 
                        <ListItem button disabled={false}>
                            <Link to="/Tabla" exact>
                                <Tooltip title="Facturas">
                                    <ListItemIcon>                                       
                                       <img src='cells.png'  alt="" width="25" height="25"/>                               
                                        
                                    </ListItemIcon>
                                </Tooltip>
                            </Link>
                          
                                <ListItemText primary="Facturas" />
                          
                        </ListItem> */}
                                               
                          <ListItem button>
                            <ListItemIcon>
                               <SeleccionAño/>
                            </ListItemIcon>                                                                                       
                                <ListItemText primary="Inegi" />                           
                        </ListItem>
                         

                        <ListItem button>                            
                                <Tooltip title="Descargar Archivo">
                                    <ListItemIcon>
                                       <PopUpDescargar/>                                      
                                    </ListItemIcon>
                                </Tooltip>                          
                                 <ListItemText primary="Descargar Archivo" />                            
                        </ListItem>
                        
                        <ListItem button>
                            <Link to="/Logout" exact>
                                <Tooltip title="Logout">
                                    <ListItemIcon>
                                       {/* <ExitToAppIcon style={{ color:'#a54400' }}/> */}
                                        <img src='log-out.png' alt="" width="25" height="25"/>
                                    </ListItemIcon>
                                </Tooltip>
                            </Link>                          
                                <ListItemText primary="Logout" />                         
                        </ListItem>
                    </List>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Typography paragraph>
                        <switch>                               
                            <Route path="/Tabla"><MaterialTableFFR/></Route>   
                            <Route path="/Inicio"><Inicio /></Route>                           
                            <Route path="/Logout"><Logout /></Route>                         
                                               
                        </switch>
                    </Typography>
                </main>
            </div>
        </Router >
    );
}

