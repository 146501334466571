import React, { Component } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Logout extends Component {       
 
    
    render() {

        fetch(`${process.env.REACT_APP_LOG}`, {
            credentials:'omit',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
           
                Usuario: cookies.get('Usu'),
                Compania: cookies.get('Comp'),               
                Actividad: "Salio del Sistema"
                
            }),
            dataType: "jsonp",

            headers:{
              'Access-Control-Allow-Origin':'*','Accept': 'application/json',
              'Content-Type': 'application/json'
            }
           

        })  
        cookies.remove('Usu', { path: "/" });       
        cookies.remove('Comp', { path: "/" });
        cookies.remove('NComp', {path:'/'});     
        cookies.remove('LComp',{path:'/'});
      


        return (

            <div>
                <script>
                    function Exit(params) {window.location.href = "/"}
                </script>
            </div>

        );
    }
}

export default Logout;