import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';

import {DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';
import { Tooltip } from '@material-ui/core';
import NumberFormat from 'react-number-format';




const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },

    root: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        background: '#EFEFEF'
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
  }));

const initialValues = {
    MetodoPago:"",
    TipoDeComprobante: "",
    Emisor:'',
    RFCEmisor: "",
    Receptor:'', 
    RFCReceptor: "", 
    Serie: "", 
    Folio: "", 
    año:"",
    mes:"",
    Descripcion:"",
    Importe:"",
    UIID:"Manual",
    ConceptoAdicional1: "",
    ConceptoAdicional2: "",
    ConceptoAdicional3: "",
    ConceptoAdicional4: "",
    ConceptoAdicional5: "",
    ConceptoAdicional6:"",
    ConceptoAdicional7:"",
    ConceptoAdicional8:"",
    Proyecto:"",
    Subproyecto:"",
    Concepto1:"",
    Concepto2:"",
    Concepto3:"",
    FechaPago:"",

    open: true
};

const Metodopago =[
    {
        value: 'PPD',
        label:'PPD',
    },
    {
        value:'PUE',
        label:'PUE',

    }
]



const onSubmit = values => {

}

const validationSchema = Yup.object({
 

})

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  }


function FormikFormAgregarConceptos(props) {
    const classes = useStyles();
    const [emisorRFC, setEmisorRFC] =useState([]);
    const [nombreEmisor, setNombreEmisor] = useState([])
    const [receptorRFC, setReceptorRFC]= useState([])
    const [nombreReceptor, setNombreReceptor] = useState([])
    const [selectedDate, handleDateChange] = useState(new Date()); 
    

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_RFCEMISOR}`)
        .then(response => response.json())
        .then(data =>{setNombreEmisor(data)})
        setTimeout(()=>{
            fetch(`${process.env.REACT_APP_RFCRECEPTOR}`)
            .then(response => response.json())
            .then(data =>{setNombreReceptor(data)})
        },1000)

       

    },[])
        
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema

    }) 

    const Nombre_Emisor = nombreEmisor.map((x)=>({
        value: x.NombreEmpresa,
        label: x.NombreEmpresa,
    }))

    const Nombre_Receptor = nombreReceptor.map((x) => ({
        value: x.NombreEmpresa,
        label: x.NombreEmpresa,
    }))

   

     const RFC_Emisor = () =>{
         fetch(`${process.env.REACT_APP_BUSCAREMISOR}=${formik.values.Emisor}`)
         .then(response => response.json())
         .then(data => setEmisorRFC(data))
     }

     const RFC_Receptor = ()=>{
         fetch(`${process.env.REACT_APP_BUSCARRECEPTOR}=${formik.values.Receptor}`)
         .then(response => response.json())
         .then(data => {setReceptorRFC(data)})
     }

  
   
  
    const ComprobanteTipo =[
        {
            value: 'I',
            label: 'I'
        },
        {
            value:'E',
            label:'E'
        }
    ]

  

  const EmiRFC = emisorRFC.map( x => x.RFC)
  const RecepRFC = receptorRFC.map(x => x.RFC)
  
  formik.values.RFCEmisor =EmiRFC[0]
  formik.values.RFCReceptor = RecepRFC[0]

   const AgregarConceptos = () =>{
    
    fetch(`${process.env.REACT_APP_EMISOR}=${formik.values.Emisor}&Receptor=${formik.values.Receptor}&Fechainput=${formik.values.FechaPago}&Metodopago=${formik.values.MetodoPago}`, {
        credentials:'omit',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({            
       
        TipoDeComprobante: formik.values.TipoDeComprobante,
       // Emisor: formik.values.Emisor, 
        RFCEmisor: formik.values.RFCEmisor, 
       // Receptor: formik.values.Receptor, 
        RFCReceptor: formik.values.RFCReceptor, 
        Serie: formik.values.Serie, 
        Folio: formik.values.Folio, 
        año: formik.values.año,
        mes: formik.values.mes,
        Descripcion: formik.values.Descripcion,
        Importe: formik.values.Importe,
        ConceptoAdicional1: formik.values.ConceptoAdicional1,
        ConceptoAdicional2: formik.values.ConceptoAdicional2,
        ConceptoAdicional3: formik.values.ConceptoAdicional3,
        ConceptoAdicional4: formik.values.ConceptoAdicional4,
        ConceptoAdicional5: formik.values.ConceptoAdicional5,
       // ConceptoAdicional6:formik.values.ConceptoAdicional6,
       // ConceptoAdicional7:formik.values.ConceptoAdicional7,
       // ConceptoAdicional8:formik.values.ConceptoAdicional8,
        Proyecto:formik.values.Proyecto,
        Subproyecto:formik.values.Subproyecto,
        FechaPago:formik.values.FechaPago,
        Concepto1:"",
        Concepto2:"",
        Concepto3:"",

            
        }), 

        dataType: "jsonp",

        headers:{
          'Access-Control-Allow-Origin':'*','Accept': 'application/json',
          'Content-Type': 'application/json'
        }
       
          
    }) 

                    setTimeout(salir, 1000);
                    store.addNotification({
                        title: "Conceptos Agregados !",
                        message: " Exitosamente!!",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],

                        dismiss: {
                            duration: 1000,
                            onScreen: true
                        }

                    });

                    function salir() {
                        window.location.href = "/Inicio"
                    }
  }
 
    


 return (
        <div className={classes.root} onMouseOver={RFC_Emisor} onMouseOverCapture={RFC_Receptor}>
            <ReactNotification/>
            <form onSubmit={formik.handleSubmit} className={classes.margin}>
                <div className="form-group">
                <Typography variant="subtitle2" gutterBottom align='center'>
                     
                </Typography>
                    <Grid container spacing={3}>  
                         <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='pago.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="standar-select-currency"
                                            select
                                            label="Método de Pago"
                                            type="text"
                                            name="MetodoPago"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.MetodoPago}>                                            
                                           

                                            {Metodopago.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}                                           
                                        </TextField>

                                        {formik.touched.MetodoPago && formik.errors.MetodoPago ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                            {formik.errors.MetodoPago}</div>) : null}
                                        
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                           <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            select
                                            label="Tipo De Comprobante"
                                            type="text"
                                            name="TipoDeComprobante"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.TipoDeComprobante}>

                                            { ComprobanteTipo.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                ))
                                            }  
                                            </TextField>

                                        {formik.touched.TipoDeComprobante && formik.errors.TipoDeComprobante ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.TipoDeComprobante}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                            
                           <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='factura.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            select
                                            id="input-with-icon-grid"
                                            label="Emisor"
                                            type="text"
                                            name="Emisor"
                                          //  onMouseOver={RFC_Emisor}
                                            
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Emisor}
                                            
                                             >

                                                { Nombre_Emisor.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                ))
                                               } 

                                            </TextField>

                                        {formik.touched.Emisor && formik.errors.Emisor ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Emisor}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='factura.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            
                                            id="input-with-icon-grid"
                                            label="RFC Emisor"
                                            type="text"
                                            name="RFCEmisor"
                                          // onMouseOver={RFC_Emisor}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.RFCEmisor}
                                            InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start"/>                                                 
                                                 
                                                ),
                                              }}
                                             />

                                                

                                        {formik.touched.RFCEmisor && formik.errors.RFCEmisor ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.RFCEmisor}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                            
                           <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='factura.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            select
                                            id="input-with-icon-grid"
                                            label="Receptor"
                                            type="text"
                                            name="Receptor"
                                           // nMouseOver={RFC_Receptor}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Receptor} >

                                                 { Nombre_Receptor.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                ))
                                               } 
                                            </TextField>

                                        {formik.touched.Receptor && formik.errors.Receptor ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Receptor}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                         
                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='factura.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="RFC Receptor"
                                            type="text"
                                            name="RFCReceptor"
                                           // onMouseOver={RFC_Receptor}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.RFCReceptor} 
                                            
                                            InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start"/>                                                 
                                                 
                                                ),
                                              }}/>

                                        {formik.touched.RFCReceptor && formik.errors.RFCReceptor ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.RFCReceptor}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                           <Grid item lg={2} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='factura.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Serie"
                                            type="text"
                                            name="Serie"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Serie} />

                                        {formik.touched.Serie && formik.errors.Serie ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Serie}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                           <Grid item lg={2} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='factura.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Folio"
                                            type="text"
                                            name="Folio"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Folio} />

                                        {formik.touched.Folio && formik.errors.Folio ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Folio}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                           <Grid item lg={2} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='calen.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Año"
                                            type="number"
                                            inputMode='numeric'
                                            name="año"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.año} />

                                        {formik.touched.año && formik.errors.año ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.año}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                           <Grid item lg={2} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='calendario.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Mes"
                                            type="number"
                                            inputMode='numeric'
                                            name="mes"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.mes} />

                                        {formik.touched.mes && formik.errors.mes ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.mes}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 

                        <Grid item lg={5} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='factura.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Descripción"
                                            type="text"
                                            name="Descripcion"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Descripcion} />

                                        {formik.touched.Descripcion && formik.errors.Descripcion ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Descripcion}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item lg={2} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='factura.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                       <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Importe"
                                            type="number"
                                            inputMode='numeric'
                                            name="Importe"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Importe} />
                                     

                                        {formik.touched.Importe && formik.errors.Importe ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Importe}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='calendario.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tooltip title='DD/MM/YYYY' arrow >
                                        <TextField
                                            fullWidth
                                            placeholder="DD/MM/YYYY"
                                            id="input-with-icon-grid"
                                            label="Fecha de Pago"
                                            type="text"
                                            name="FechaPago"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.FechaPago} />
                                            </Tooltip>

                                        {formik.touched.FechaPago && formik.errors.FechaPago ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.FechaPago}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 

                       {/**   
                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='calendario.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                  <DatePicker
                                      disableFuture
                                      fullWidth
                                       openTo="year"
                                       format="DD/MM/yyyy"
                                        label="Fecha de Pago"
                                        name="FechaPago"
                                        views={["year", "month", "date"]}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                       
                                         /> 

                                         <KeyboardDatePicker
                                                                             
                                        margin="normal"                                                                          
                                        label="Fecha de Pago"
                                        format="DD/MM/YYYY"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                         'aria-label': 'change date',
                                           }}
                                     
                                             />
                                    </MuiPickersUtilsProvider>                             
                                                                       

                                        {formik.touched.FechaPago && formik.errors.FechaPago ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.FechaPago}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                                */} 
                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Proyecto"
                                            type="text"
                                            name="Proyecto"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Proyecto} />

                                        {formik.touched.Proyecto && formik.errors.Proyecto ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Proyecto}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 

                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Subproyecto"
                                            type="text"
                                            name="Subproyecto"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Subproyecto} />

                                        {formik.touched.Subproyecto && formik.errors.Subproyecto ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Subproyecto}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 

                



                       <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto1"
                                            type="text"
                                            name="ConceptoAdicional1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional1} />

                                        {formik.touched.ConceptoAdicional1 && formik.errors.ConceptoAdicional1 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional1}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto2"
                                            type="text"
                                            name="ConceptoAdicional2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional2} />

                                        {formik.touched.ConceptoAdicional2 && formik.errors.ConceptoAdicional2 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional2}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>  

                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto3"
                                            type="text"
                                            name="ConceptoAdicional3"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional3} />

                                        {formik.touched.ConceptoAdicional3 && formik.errors.ConceptoAdicional3 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional3}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 

                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto4"
                                            type="text"
                                            name="ConceptoAdicional4"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional4} />

                                        {formik.touched.ConceptoAdicional4 && formik.errors.ConceptoAdicional4 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional4}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 
                     
                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto5"
                                            type="text"
                                            name="ConceptoAdicional5"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional5} />

                                        {formik.touched.ConceptoAdicional5 && formik.errors.ConceptoAdicional5 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional5}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 
                  {/** 
                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto6"
                                            type="text"
                                            name="ConceptoAdicional6"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional6} />

                                        {formik.touched.ConceptoAdicional6 && formik.errors.ConceptoAdicional6 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional6}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 

                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto7"
                                            type="text"
                                            name="ConceptoAdicional7"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional7} />

                                        {formik.touched.ConceptoAdicional7 && formik.errors.ConceptoAdicional7 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional7}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 

                        <Grid item lg={3} sm={6} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto8"
                                            type="text"
                                            name="ConceptoAdicional8"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional8} />

                                        {formik.touched.ConceptoAdicional8 && formik.errors.ConceptoAdicional8 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional8}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 
                                        */} 


                    </Grid> 

                    <br />
                    <button className="btn btn-primary" 
                       style={{ width: "150px" }}
                        color="Primary" type="submit"
                        onClick={AgregarConceptos}>
                        <PostAddIcon /> <strong><a>Agregar</a></strong> </button>                 


                </div>
            </form>
        </div>

    );
}

export default FormikFormAgregarConceptos;