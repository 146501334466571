import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import { useFormik, Formik } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MenuItem from '@material-ui/core/MenuItem';
import TablaFFR from './PopUpTablaFacturas';


const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },

    root: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        background: '#EFEFEF'
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
  }));
var f=new Date()
const initialValues = {
    
    Año:f.getFullYear(),
    open: true
};

const Año =[
    {
        value: '2017',
        label:'Año 2017',
    },
    {
        value:'2018',
        label:'Año 2018',

    },
    {
        value: '2019',
        label:'Año 2019',
    },
    {
        value:'2020',
        label:'Año 2020',

    },
    {
        value: '2021',
        label:'Año 2021',
    },
    {
        value:'2022',
        label:'Año 2022',

    },
    {
        value: '2023',
        label:'Año 2023',
    },
    {
        value:'2024',
        label:'Año 2024',

    }
]

const onSubmit = values => {

}

const validationSchema = Yup.object({
 

})


function FormikSeleccionAño(props) {
    const classes = useStyles();
        
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema

    }) 
  

  
 return (
        <div className={classes.root} >
            <ReactNotification/>
            <form onSubmit={formik.handleSubmit} className={classes.margin}>
                <div className="form-group">
                <Typography variant="subtitle2" gutterBottom align='center'>
                     
                </Typography>
                    <Grid container
                         direction="column"
                         justifyContent="center"
                         alignItems="baseline"
                         xs={12} >

                    <Grid item >
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='año.png' width='30' />
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            fullWidth={true}
                                            width={400}
                                            id="standar-select-currency"
                                            select
                                            label="Año"
                                            type="text"
                                            name="Año"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Año}>                                            
                                           

                                            {Año.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}                                           
                                        </TextField>

                                        {formik.touched.Año && formik.errors.Año ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                            {formik.errors.Año}</div>) : null}
                                        
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                           





                    </Grid> 

                    <br />
                       
                        <TablaFFR
                        año={formik.values.Año}
                        />
                </div>
            </form>
        </div>

    );
}

export default FormikSeleccionAño;