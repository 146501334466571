import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },

    root: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        background: '#EFEFEF'
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
  }));

const initialValues = {
    Serie: "",
    Folio: "", 
    open: true
};

const onSubmit = values => {

}

const validationSchema = Yup.object({
 

})


function FormikFormBorrarConceptos(props) {
    const classes = useStyles();
        
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema

    }) 
 
   const BorrarConceptos = () =>{
    
    

    fetch(`${process.env.REACT_APP_BORRAR}=${props.serie}&Folio=${props.folio}&BuscaConcepto1=${props.concepto1}&BuscaConcepto2=${props.concepto2}&BuscaConcepto4=${props.concepto4}&BuscaProyecto=${props.proyecto}&BuscaSubProyecto=${props.subproyecto}&UUIDFinal=${props.UUID}&BuscaAño=${props.año}&BuscaMes=${props.mes}`, {
        credentials:'omit',
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
            
       
        
        //Serie: formik.values.Serie,
       // Folio: formik.values.Folio,
     

            
        }), 

        dataType: "jsonp",

        headers:{
          'Access-Control-Allow-Origin':'*','Accept': 'application/json',
          'Content-Type': 'application/json'
        }
       
          
    }) 

                    setTimeout(salir, 1000);
                    store.addNotification({
                        title: "Conceptos Agregados !",
                        message: " Exitosamente!!",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],

                        dismiss: {
                            duration: 1000,
                            onScreen: true
                        }

                    });

                    function salir() {
                        window.location.href = "/Inicio"
                    }
  }
 return (
        <div className={classes.root} >
            <ReactNotification/>
            <form onSubmit={formik.handleSubmit} className={classes.margin}>
                <div className="form-group">

                <Typography variant="h6" gutterBottom align='center'>
                Está Seguro de Borrar los Siguientes Datos?
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Año:  <a style={{color:'red'}}>{props.año}</a>
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mes:  <a style={{color:'red'}}>{props.mes}</a>
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;UUID:  <a style={{color:'red'}}>{props.UUID}</a>
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Serie:  <a style={{color:'red'}}>{props.serie}</a>
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Folio:  <a style={{color:'red'}}>{props.folio}</a>
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Proyecto: <a style={{color:'red'}}>{props.proyecto}</a> 
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subproyecto:  <a style={{color:'red'}}>{props.subproyecto}</a>
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Concepto 1:  <a style={{color:'red'}}>{props.concepto1}</a>
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Concepto 2:  <a style={{color:'red'}}>{props.concepto2}</a>
                </Typography>
                <Typography variant="subtitle2" gutterBottom align='left'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Concepto 4:  <a style={{color:'red'}}>{props.concepto4}</a>
                </Typography>

                  
                 
                  
                  
                   

      {/**               <Grid container spacing={3}>                     
                       <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Serie"
                                            type="text"
                                            name="Serie"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Serie} />

                                        {formik.touched.Serie && formik.errors.Serie ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Serie}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Folio"
                                            type="text"
                                            name="Folio"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Folio} />

                                        {formik.touched.Folio && formik.errors.Folio ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Folio}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>  





                    </Grid> 
*/}
                    <br />
                    <button className="btn btn-primary" 
                       style={{ width: "150px" }}
                        color="Primary" type="submit"
                        onClick={BorrarConceptos}>
                        <DeleteForeverIcon /> <strong><a>Borrar</a></strong> </button>                 


                </div>
            </form>
        </div>

    );
}

export default FormikFormBorrarConceptos;