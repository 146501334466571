import React, { Component } from 'react';
import './Login.css';
import Cookies from 'universal-cookie';
import Alert from '@material-ui/lab/Alert';


const cookies = new Cookies();

class Inicio extends Component {

 
   
    componentDidMount() {
        if (!cookies.get('Usu')) {
            window.location.href = "/";
        }
        
    }
    render() {
        return (
            <div>
                
               <div className="containerPrincipal" >
              
                    <div className="containerSecundario">
                       
                        <img className="text-center img-fluid z-depth-0" src={cookies.get('LComp')} alt="" />
                       
                   </div>
                   
                   
                  
               </div>
              
            </div>
        );
    }
}

export default Inicio;