import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import {DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';



const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },

    root: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        background: '#EFEFEF'
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
  }));

const initialValues = {
    FechaPago:"",
    MetodoPago:"",
    Proyecto:"",
    Subproyecto:"",
    Importe:"",
    ConceptoAdicional1: "",
    ConceptoAdicional2: "", 
   // ConceptoAdicional3: "", 
    ConceptoAdicional4: "", 
    ConceptoAdicional5: "", 

    open: true
};

const onSubmit = values => {

}

const validationSchema = Yup.object({
 

})
const Metodopago =[
    {
        value: 'PPD',
        label:'PPD',
    },
    {
        value:'PUE',
        label:'PUE',

    }
]

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  }


function FormikFormEditarConceptos(props) {
    const [selectedDate, handleDateChange] = useState(new Date(initialValues.FechaPago)); 
    const classes = useStyles();
        
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema

    }) 
    useEffect(() =>{
       
       
        setTimeout(actualizar, 500);
        function actualizar(){
            handleDateChange(initialValues.FechaPago)

            
        }      

    },[])

   initialValues.FechaPago=props.fechapago
   initialValues.MetodoPago=props.metodopago 
   initialValues.Proyecto=props.proyecto
   initialValues.Subproyecto=props.subproyecto
   initialValues.Importe=props.importe
   initialValues.ConceptoAdicional1=props.concepto1
   initialValues.ConceptoAdicional2=props.concepto2
   initialValues.ConceptoAdicional3=props.concepto3
   initialValues.ConceptoAdicional4=props.concepto4
   initialValues.ConceptoAdicional5=props.concepto5

  

   const AgregarConceptos = () =>{

    
    
    fetch(`${process.env.REACT_APP_EDITAR}&Fechapago=${formik.values.FechaPago}&BuscaConcepto1=${initialValues.ConceptoAdicional1}&BuscaConcepto2=${initialValues.ConceptoAdicional2}&BuscaConcepto4=${initialValues.ConceptoAdicional4}&BuscaProyecto=${initialValues.Proyecto}&BuscaSubProyecto=${initialValues.Subproyecto}`, {
        credentials:'omit',
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
            
       ////////estos parametros son de lectura///
        UUID: props.UUID,
        Serie:props.serie,
        Folio:props.folio,
        año:props.año,
        mes:props.mes,
       ////////estos parametros son los que se modifican////
       
        Proyecto:formik.values.Proyecto,
        Subproyecto:formik.values.Subproyecto,
        Importe:formik.values.Importe,
        ConceptoAdicional1: formik.values.ConceptoAdicional1,
        ConceptoAdicional2: formik.values.ConceptoAdicional2,
        ConceptoAdicional3: formik.values.ConceptoAdicional3,
        ConceptoAdicional4: formik.values.ConceptoAdicional4,
        ConceptoAdicional5: formik.values.ConceptoAdicional5

            
        }), 

        dataType: "jsonp",

        headers:{
          'Access-Control-Allow-Origin':'*','Accept': 'application/json',
          'Content-Type': 'application/json'
        }
       
          
    }) 

                    setTimeout(salir, 1000);
                    store.addNotification({
                        title: "Conceptos Agregados !",
                        message: " Exitosamente!!",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],

                        dismiss: {
                            duration: 1000,
                            onScreen: true
                        }

                    });

                    function salir() {
                        window.location.href = "/Inicio"
                    }
  }
 return (
        <div className={classes.root} >
            <ReactNotification/>
            <form onSubmit={formik.handleSubmit} className={classes.margin}>
                <div className="form-group">
                <Typography variant="subtitle2" gutterBottom align='center'>
                     
                </Typography>
                    <Grid container spacing={3}>   
                       
                        
                   {/**   <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='calendario.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                   <DatePicker
                                      disableFuture
                                      fullWidth
                                       openTo="year"
                                       format="dd/MM/yyyy"
                                        label="Fecha de Pago"
                                        name="FechaPago"
                                        views={["year", "month", "date"]}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                       
                                         />
                                  
                                    <KeyboardDatePicker
                                        fullWidth
                                       
                                        margin="normal"
                                        id="input-with-icon-grid"
                                      
                                        label="Fecha de Pago"
                                        format="DD/MM/YYYY"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                         'aria-label': 'change date',
                                           }}
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start"/>                                                 
                                             
                                            ),
                                          }}
                                             />     
                                    </MuiPickersUtilsProvider>                             
                                                                       

                                        {formik.touched.FechaPago && formik.errors.FechaPago ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.FechaPago}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>  */}
                        <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='calendario.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="date"
                                            label="Fecha de Pago"                                           
                                            name="FechaPago"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.FechaPago} />

                                        {formik.touched.FechaPago && formik.errors.FechaPago ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.FechaPago}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        {/** 
                        <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='pago.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="standar-select-currency"
                                            select
                                            label="Método de Pago"
                                            type="text"
                                            name="MetodoPago"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.MetodoPago}>                                            
                                           

                                            {Metodopago.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}                                           
                                        </TextField>

                                        {formik.touched.MetodoPago && formik.errors.MetodoPago ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                            {formik.errors.MetodoPago}</div>) : null}
                                        
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        */}
                        
                       <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Proyecto"
                                            type="text"
                                            name="Proyecto"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Proyecto} />

                                        {formik.touched.Proyecto && formik.errors.Proyecto ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Proyecto}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        
                       <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Subproyecto"
                                            type="text"
                                            name="Subproyecto"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Subproyecto} />

                                        {formik.touched.Subproyecto && formik.errors.Subproyecto ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Subproyecto}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        
                      

                        
                       <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Importe"
                                            type="number"
                                            inputMode='numeric'
                                            name="Importe"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Importe} />

                                        {formik.touched.Importe && formik.errors.Importe ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.Importe}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                       <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto 1"
                                            type="text"
                                            name="ConceptoAdicional1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional1} />

                                        {formik.touched.ConceptoAdicional1 && formik.errors.ConceptoAdicional1 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional1}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto 2"
                                            type="text"
                                            name="ConceptoAdicional2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional2} />

                                        {formik.touched.ConceptoAdicional2 && formik.errors.ConceptoAdicional2 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional2}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>  
{/**                        <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto 3"
                                            type="text"
                                            name="ConceptoAdicional3"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional3} />

                                        {formik.touched.ConceptoAdicional3 && formik.errors.ConceptoAdicional3 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional3}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 
 */}

                        <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto 4"
                                            type="text"
                                            name="ConceptoAdicional4"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional4} />

                                        {formik.touched.ConceptoAdicional4 && formik.errors.ConceptoAdicional4 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional4}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 

                        <Grid item  sm={12} xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>

                                        <img src='libretas.png' width='30' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-grid"
                                            label="Concepto 5"
                                            type="text"
                                            name="ConceptoAdicional5"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ConceptoAdicional5} />

                                        {formik.touched.ConceptoAdicional5 && formik.errors.ConceptoAdicional5 ?
                                            (<div className="error"><img src='warning3.png' width='20' />
                                             {formik.errors.ConceptoAdicional5}</div>) : null}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 
  


                    </Grid> 

                    <br />
                    <button className="btn btn-primary" 
                       style={{ width: "150px" }}
                        color="Primary" type="submit"
                        onClick={AgregarConceptos}>
                        <SaveIcon /> <strong><a>Guardar</a></strong> </button>                 


                </div>
            </form>
        </div>

    );
}

export default FormikFormEditarConceptos;